<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5 class="mb-2">Client Data Exports</h5>
        <div class="card border-0 shadow-sm">
          <div class="card-body pt-2">
            <busy :visible="loading" />
            <div v-if="!loading && dataExports.length === 0" class="text-center mt-2">
              <p>
                <i class="far fa-info-circle fa-3x text-primary"></i>
              </p>
              <p>No data exports found.</p>
            </div>
            <div v-else>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col" style="border-top: 0px">#</th>
                  <th scope="col" style="border-top: 0px">Date</th>
                  <th scope="col" style="border-top: 0px">Client</th>
                  <th scope="col" style="border-top: 0px">Type</th>
                  <th scope="col" style="border-top: 0px">Filename</th>
                  <th scope="col" style="border-top: 0px"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="d in dataExports" :key="d.id">
                  <th scope="row">#{{ d.id }}</th>
                  <td>{{ d.created_at | formatDate }}</td>
                  <td @click="viewClient(d.client_id)"><a href="#">{{ d.client ? d.client.name : '' }}</a></td>
                  <td class="text-capitalize">{{ d.type | formatStatus }}</td>
                  <td>{{ d.filename }}</td>
                  <td class="text-end">
                    <button v-if="saving === d.id || (d.url !== 'deleted' && !d.downloaded)"
                            class="btn btn-primary btn-sm"
                            @click="downloadExport(d)"
                            :disabled="saving"
                    >
                      <i class="far me-1" :class="saving ? 'fa-spin fa-circle-notch' : 'fa-download'"></i>
                      Download
                    </button>
                    <span v-else class="badge bg-warning">Expired</span>
                  </td>
                </tr>
                </tbody>
              </table>
              <!--  -->
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col my-auto">
                      <button
                          class="btn btn-sm btn-light"
                          @click="fetchDataExports(pagination.first_page_url)"
                          :disabled="!pagination.first_page_url"
                      >
                        First Page
                      </button>

                      <button
                          class="btn btn-sm btn-light ms-3"
                          @click="fetchDataExports(pagination.prev_page_url)"
                          :disabled="!pagination.prev_page_url"
                      >
                        Previous
                      </button>
                    </div>
                    <div class="col my-auto text-center">
                      <p class="mb-0">
                        Page {{ pagination.current_page }} of
                        {{ pagination.last_page }}
                      </p>
                    </div>
                    <div class="col my-auto text-end">
                      <button
                          class="btn btn-sm btn-light cursor-pointer me-3"
                          @click="fetchDataExports(pagination.next_page_url)"
                          :disabled="!pagination.next_page_url"
                      >
                        Next
                      </button>

                      <button
                          class="btn btn-sm btn-light cursor-pointer"
                          @click="fetchDataExports(pagination.last_page_url)"
                          :disabled="!pagination.last_page_url"
                      >
                        Last Page
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      loading: true,
      saving: false,
      dataExports: [],
      pagination: {},
    };
  },
  methods: {
    fetchDataExports(paginationUrl) {
      this.loading = true;
      var url = paginationUrl ? paginationUrl : process.env.VUE_APP_API_URL + "/client-data-exports";

      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.dataExports = data.data;
        this.loading = false;
      });
    },
    downloadExport(d) {
      this.saving = d.id;
      d.downloaded = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/client-data-exports/" + d.id, d).then(({data}) => {
        this.saving = false;
        window.location = d.url;
      });
    },
    viewClient(id) {
      this.$router.push("/clients/" + id);
    }
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("Do MMM YYYY") : "Not Set";
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
  created() {
    this.fetchDataExports();
  },
};
</script>

<style>
</style>
